import React, { useState, useEffect } from "react";
import { StaticImage } from "gatsby-plugin-image";
import { RichText } from "./rich-text";
import Button from "./button";
import { Asset } from "./asset";
import replaceUrl from "replace-url-by-params";
import queryString from "query-string";

export interface PhotoCardsProps {
  title: string;
  description: {
    raw: string;
  };
  image: {
    title: string;
    localFile: {
      childImageSharp: {
        gatsbyImageData: any; // Replace with the actual type if available
      };
    };
  };
  text: string;
  photoBy: string;
  link: string;
  location: any;
}

const PhotoCards: React.FC<PhotoCardsProps> = ({
  title,
  description,
  image,
  text,
  photoBy,
  link,
  location
}) => {
  const {
    taboola_click_id,
    click_id,
    campaign,
    utm_source,
    adgroup,
    campaign_item_id,
    creative,
  }: any = queryString.parse(location.search);

  const [finalLink, setFinalLink] = useState("");

  useEffect(() => {
    setFinalLink(
      replaceUrl(link, {
        network_name: utm_source,
        campaign_name: campaign,
        campaign_id: campaign,
        adgroup_name: adgroup,
        site: adgroup,
        taboola_click_id: taboola_click_id || click_id,
        click_id: taboola_click_id || click_id,
        campaign_item_id: creative || campaign_item_id,
        creative,
      })
    );
  }, []);

  return (
    <div className="photo-cards mb-4">
      {title && <h3 className="font-bold text-[14px] mb-1">{title}</h3>}
      <RichText contentfullText={description} />
      <div className="flex mt-[20px] ">
        <Asset className="flex-[1_0_60%]" data={image} />
        {text && <div className="flex-[1_0_35%] p-4 bg-[#00000040] text-[10px] font-bold">{text}</div>}
      </div>
      <div className="text-[10px] mt-2">Photo by {photoBy} for Pexels</div>
      <div>
        <p className="font-bold text-[10px]">Download Now:</p>
        <div className="flex gap-2 mt-1">
          <a href={finalLink}>
            <StaticImage
              src="../images/android.png"
              alt="App Store"
              width={80}
              quality={100}
            />
          </a>
          <a href={finalLink}>
            <StaticImage
              src="../images/ios.png"
              alt="App Store"
              width={80}
              quality={100}
            />
          </a>
        </div>
      </div>
    </div>
  );
};

export default PhotoCards;
